import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Modal, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import { useHistory } from "react-router-dom";

import { QuestionBankTestModes, QuestionTypes } from "../../../constants";
import ContentLoader from "../../../layouts/TableContentLayout/components/ContentLoader";
import { ContentAreaPassingStatus } from "../../../models";
import { IExtendedTheme } from "../../../theme/default";
import { getSanitizedHtmlText } from "../../../utils/serviceUtils";
import Button from "../../Button";

import {
	BowTieResult,
	CaseStudyQuestionResult,
	ClozeDropDownResult,
	DragAndDropResult,
	DropDownTableResult,
	HighlightTableResult,
	HotspotHighlightResult,
	MatrixMultipleChoiceResult,
	MatrixSingleChoiceResult,
	MultipleResponseGroupResult,
	SingleChoiceOrMultipleChoiceResult
} from "../Components";

import {
	CATButtonRow,
	CATContentAreaContainer,
	CATContentAreaDesc,
	CATContentAreaStatus,
	CATContentAreas,
	CATExpand,
	CATResultsContainer,
	CATResultsHeader,
	CATResultsTypography,
	CATTestStatus,
	NoResults,
	QuestionResultInfoContainer,
	useStyles
} from "../styles";

interface ContentAreas {
	description: string;
	maximumPercentage: number;
	minimumPercentage: number;
	name: string;
	status: string;
}
const QUESTIONS_PER_PAGE = 5;
const ExtendedResults = ({
	result: results,
	onClose,
	userQuestion,
	testModeType = "",
	contentAreas = [] as ContentAreas[],
	catAttemptedQuestionsCount = null,
	currentTest,
	isLoading,
	QuestionResultInfoComponent,
	attemptsCount
}) => {
	const theme = useTheme<IExtendedTheme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles({ isMobile });
	const [page, setPage] = useState(1); // Stop fetching when no more data
	const [modalContent, setModalContent] = useState<HTMLDivElement | null>(null);

	const [paginatedQuestions, setPaginatedQuestions] = useState(userQuestion.slice(0, page * QUESTIONS_PER_PAGE));
	const [expanded, setExpanded] = useState(false);

	const modalContentRef = useCallback((node: HTMLDivElement | null) => {
		if (node !== null) {
			setModalContent(node);
		}
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			if (!modalContent) return;

			const { scrollTop, scrollHeight, clientHeight } = modalContent;
			if (scrollTop + clientHeight >= scrollHeight - 5) {
				setPage(p => {
					const newPage = p + 1;
					setPaginatedQuestions(userQuestion.slice(0, newPage * QUESTIONS_PER_PAGE));
					return newPage;
				});
			}
		};

		if (modalContent) {
			modalContent.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (modalContent) {
				modalContent.removeEventListener("scroll", handleScroll);
			}
		};
	}, [modalContent]);

	const getQuestionType = (id, result, question, results) => {
		switch (id) {
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceResult question={question} result={result} />;
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.ClozeDropDown:
				return <ClozeDropDownResult question={question} result={result} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return <DragAndDropResult question={question} result={result} />;
			case QuestionTypes.SingleChoice:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.CaseStudy:
				return (
					<CaseStudyQuestionResult
						question={question}
						results={results}
						attemptsCount={attemptsCount}
						QuestionResultInfoComponent={QuestionResultInfoComponent}
					/>
				);
			case QuestionTypes.DropDownTable:
				return <DropDownTableResult question={question} result={result} />;
			case QuestionTypes.BowTie:
				return <BowTieResult result={result} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupResult question={question} result={result} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightResult question={question} result={result} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableResult question={question} result={result} />;
			default:
				return null;
		}
	};

	const history = useHistory();
	const CATStatusTitle = {
		[ContentAreaPassingStatus.BelowPassing]: "Below the Passing Standard",
		[ContentAreaPassingStatus.AbovePassing]: "Above the Passing Standard",
		[ContentAreaPassingStatus.NearPassing]: "Near the Passing Standard"
	};
	const courseIdToName = {
		2: "RN",
		3: "PN"
	};

	const answers = currentTest.userQuestionAttempts as { subQuestionId; questionId }[];
	const questionCount = useMemo(() => answers.length, [answers]);
	const loadingValue = useMemo(() => isLoading, [isLoading]);

	const minimumCATQuestions = useMemo(() => {
		if ((currentTest.typeId || testModeType) === QuestionBankTestModes.CAT) {
			const {
				data: {
					CATConfiguration: {
						data: { preTestQuestionsCount, minimumQuestionsCount }
					}
				}
			} = currentTest;
			return preTestQuestionsCount + minimumQuestionsCount;
		} else {
			return 0;
		}
	}, [currentTest, testModeType]);

	const maximumCATQuestions = useMemo(() => {
		if ((currentTest.typeId || testModeType) === QuestionBankTestModes.CAT) {
			const {
				data: {
					CATConfiguration: {
						data: { preTestQuestionsCount, maximumQuestionsCount }
					}
				}
			} = currentTest;
			return preTestQuestionsCount + maximumQuestionsCount;
		} else {
			return 0;
		}
	}, [currentTest, testModeType]);

	return (
		<Modal open onClose={onClose} className={classes.modal}>
			<div className={classes.scroll}>
				<div className={classes.content}>
					<div className={classes.header}>
						<div className={classes.title}>
							{(currentTest.typeId || testModeType) === QuestionBankTestModes.CAT && "CAT "}Test Results
						</div>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</div>
					{loadingValue && (!results || (results && results.length === 0)) ? (
						<ContentLoader />
					) : results.length === 0 && currentTest.typeId != QuestionBankTestModes.CAT ? (
						<NoResults>Sorry, No Results Available</NoResults>
					) : (currentTest.typeId || testModeType) === QuestionBankTestModes.CAT ? (
						<CATResultsContainer>
							<CATResultsHeader>NCLEX-{courseIdToName[currentTest.courseId]} CAT Perfomance Report</CATResultsHeader>
							<CATResultsTypography flexColumn={isMobile}>
								You{" "}
								<CATTestStatus passed={currentTest.passed}>
									{currentTest.passed ? "HAVE PASSED" : "HAVE NOT PASSED"}
								</CATTestStatus>{" "}
								Computerized Adaptive Test (CAT)
							</CATResultsTypography>
							<CATResultsTypography>
								The NCLEX-{courseIdToName[currentTest.courseId]} Computerized Adaptive Test (CAT) administers test items
								until a precise pass or fail decision can be made. On CAT, no student is administered fewer than{" "}
								{minimumCATQuestions} items or more than {maximumCATQuestions} items. You answered{" "}
								<u>
									<b>{catAttemptedQuestionsCount || questionCount}</b>
								</u>{" "}
								items. Only students whose performance was close to the passing standard, either just above it or just
								below it, had to answer the maximum number of {maximumCATQuestions} items. Fewer items were required for
								a precise pass-fail decision to be made for those students whose performance was much lower than the
								passing standard.
							</CATResultsTypography>
							<CATResultsHeader noMargin>About the test</CATResultsHeader>
							<CATResultsTypography>
								To pass the Computerized Adaptive Test (CAT), a student must perform ABOVE the passing standard.
								Students fail if they only meet{" "}
								{isMobile && !expanded ? (
									<>
										...<CATExpand onClick={() => setExpanded(true)}>Read more</CATExpand>
									</>
								) : (
									<>
										the passing standard or are below it. The purpose of this CAT Performance Report is to give you
										information about your performance on the specific content areas of the test.
									</>
								)}
							</CATResultsTypography>
							{((isMobile && expanded) || !isMobile) && (
								<>
									<CATResultsTypography>
										For each content area of the CAT, you will find the percentage of the test represented by that
										content area, statement of your performance (your ability in that content area), and description of
										the content area with a list of topics related to it. Your performance in each content area is
										described in one of the following ways:
									</CATResultsTypography>
									<CATContentAreas>
										- Above the Passing Standard
										<br />
										- Near the Passing Standard
										<br />- Below the Passing Standard
									</CATContentAreas>
									<CATResultsTypography extraBottomPadding>
										These descriptions are designed to help you understand the strengths and weaknesses of your
										performance. Use this information to help guide your study before you take this test again. Please
										note that &quot;near the passing standard&quot; does not imply a satisfactory performance on the
										content area. Also, you cannot add your performance in the content areas (i.e. above, near, or below
										the passing standard) to validate the pass-fail decision. If your ability is above the passing
										standard in given content area, you should still study that area to maintain your proficiency.
									</CATResultsTypography>{" "}
								</>
							)}

							<CATResultsHeader noMargin>Content Areas</CATResultsHeader>
							<CATResultsTypography>
								The following describes your performance on each content area.
							</CATResultsTypography>
							{contentAreas.length !== 0
								? contentAreas.map((result, index) => (
										<CATContentAreaContainer key={index}>
											<CATResultsTypography fullWidth={isMobile}>
												{result.name} ({result.minimumPercentage} - {result.maximumPercentage}% of the test)
											</CATResultsTypography>
											<CATContentAreaStatus
												passed={result.status === ContentAreaPassingStatus.AbovePassing}
												medium={result.status === ContentAreaPassingStatus.NearPassing}
											>
												- {CATStatusTitle[result.status]}
											</CATContentAreaStatus>
											{result.description && (
												<CATContentAreaDesc>{getSanitizedHtmlText(result.description)}</CATContentAreaDesc>
											)}
										</CATContentAreaContainer>
								  ))
								: currentTest?.data?.result?.contentAreas.map((result, index) => (
										<CATContentAreaContainer key={index}>
											<CATResultsTypography fullWidth={isMobile}>
												{result.name} ({result.minimumPercentage} - {result.maximumPercentage}% of the test)
											</CATResultsTypography>
											<CATContentAreaStatus
												passed={result.status === ContentAreaPassingStatus.AbovePassing}
												medium={result.status === ContentAreaPassingStatus.NearPassing}
											>
												- {CATStatusTitle[result.status]}
											</CATContentAreaStatus>
											{result.description && (
												<CATContentAreaDesc>{getSanitizedHtmlText(result.description)}</CATContentAreaDesc>
											)}
										</CATContentAreaContainer>
								  ))}
							<CATButtonRow fullWidth={isMobile}>
								<Button variant="filled" color="primary" onClick={() => history.push("/")}>
									Go to Homepage
								</Button>
							</CATButtonRow>
						</CATResultsContainer>
					) : (
						<div style={{ height: "90vh", overflowX: "auto" }} ref={modalContentRef}>
							{paginatedQuestions.map((question, resultIndex) => {
								const result = results.find(x => x.snapshot.id === question.id) || {
									snapshot: question,
									userCustomTestId: currentTest.id,
									selectedAnswers: [],
									percentageGrade: 0
								};
								return (
									<div
										key={`user-question-attempt-${question.id}`}
										// ref={resultIndex === paginatedQuestions.length - 1 ? lastItemRef : null}
										className={classes.questionContainer}
									>
										<div className={classes.row}>
											<div className={question.typeId !== QuestionTypes.CaseStudy ? classes.halfCol : classes.fullCol}>
												<div className={classes.questionNumber}>Question {resultIndex + 1}</div>
												<Typography className={classes.questionText}>{getSanitizedHtmlText(question?.text)}</Typography>
												<Box
													style={{ color: theme.palette.colors.basic[600] }}
													dangerouslySetInnerHTML={{ __html: question?.description }}
												/>
												{getQuestionType(question.typeId, result, question, results)}
											</div>
											{QuestionResultInfoComponent && question.typeId !== QuestionTypes.CaseStudy && (
												<QuestionResultInfoContainer isMobile={isMobile}>
													<QuestionResultInfoComponent result={result} attemptsCount={attemptsCount} />
												</QuestionResultInfoContainer>
											)}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ExtendedResults;

import { useEffect, useRef } from "react";

import { FirebaseApp } from "firebase/app";
import { Messaging, getMessaging, isSupported } from "firebase/messaging";

export const useFirebaseMessaging = (firebaseApp: FirebaseApp) => {
	const messagingRef = useRef<Messaging | null>(null); // Properly typed

	useEffect(() => {
		const setupMessaging = async () => {
			const supported = await isSupported();
			if (supported) {
				messagingRef.current = getMessaging(firebaseApp);
				console.log("Firebase Messaging initialized");
			} else {
				console.warn("Firebase Messaging is not supported in this browser.");
			}
		};

		setupMessaging();
	}, [firebaseApp]);

	return messagingRef; // You can access via messagingRef.current
};

import React, { useMemo, useState } from "react";

import { Box, Typography, useTheme } from "@material-ui/core";

import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import ClozeDropDownResult from "./ClozeDropDownResult";
import DragAndDropResult from "./DragAndDropResult";
import DropDownTableResult from "./DropDownTableResult";
import HighlightTableResult from "./HighlightTableResult";
import HotspotHighlightResult from "./HotspotHighlightResult";
import MatrixMultipleChoiceResult from "./MatrixMultipleChoiceResult";
import MatrixSingleChoiceResult from "./MatrixSingleChoiceResult";
import MultipleResponseGroupResult from "./MultipleResponseGroupResult";
import SingleChoiceOrMultipleChoiceResult from "./SingleChoiceOrMultipleChoiceResult";

import {
	CaseStudyColumn,
	CaseStudyRow,
	CaseStudyTab,
	CaseStudyTabContent,
	CaseStudyTabs,
	CurrentPageNum,
	IconsContainer,
	Pages,
	QuestionContainer,
	StyledIcon
} from "./styles";

import { QuestionTypes } from "../../../constants";

import { IExtendedTheme } from "../../../theme/default";
import { getSanitizedHtmlText } from "../../../utils/serviceUtils";

import { useStyles } from "../styles";

const CaseStudyQuestionResult = ({
	results,
	attemptsCount,
	question: {
		caseStudyQuestions: unorderedQuestions,
		data: { tabs: parentTabs }
	},
	QuestionResultInfoComponent
}) => {
	const classes = useStyles();
	const theme = useTheme<IExtendedTheme>();
	const [activeTab, setActiveTab] = useState(0);
	const [activeQuestion, setActiveQuestion] = useState(0);
	const questions = useMemo(() => [...unorderedQuestions].sort((a, b) => a.order - b.order), [unorderedQuestions]);
	const currentQuestion = questions[activeQuestion];
	const {
		data: { tabs: currentTabs },
		text,
		description
	} = currentQuestion;
	const tabs = currentTabs || parentTabs;
	const result = useMemo(
		() =>
			results.find(x => x.snapshot.id === currentQuestion?.id) || {
				snapshot: currentQuestion,
				userCustomTestId: results[0]?.userCustomTestId || 0,
				selectedAnswers: [],
				percentageGrade: 0
			},
		[currentQuestion?.id, results]
	);
	const setActiveQuestionIndex = (index: number) => {
		setActiveTab(0);
		setActiveQuestion(index);
	};

	const getQuestionType = (id, result, question) => {
		switch (id) {
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceResult question={question} result={result} />;
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.ClozeDropDown:
				return <ClozeDropDownResult question={question} result={result} />;
			case QuestionTypes.DropDownTable:
				return <DropDownTableResult question={question} result={result} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
			case QuestionTypes.SingleChoice:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.RationalScoringDragAndDrop:
			case QuestionTypes.DragAndDrop:
				return <DragAndDropResult question={question} result={result} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupResult question={question} result={result} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightResult question={question} result={result} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableResult question={question} result={result} />;

			default:
				return null;
		}
	};

	return (
		<>
			<CaseStudyRow>
				<CaseStudyColumn className={classes.leftCol}>
					<CaseStudyTabs>
						{tabs.map(({ title, id }, tabIndex) => (
							<CaseStudyTab key={`tab-${id}`} active={tabIndex === activeTab} onClick={() => setActiveTab(tabIndex)}>
								{title}
							</CaseStudyTab>
						))}
					</CaseStudyTabs>
					{activeTab !== undefined && (
						<CaseStudyTabContent dangerouslySetInnerHTML={{ __html: tabs[activeTab]?.text }}></CaseStudyTabContent>
					)}
				</CaseStudyColumn>
				<CaseStudyColumn className={classes.rightCol}>
					<Pages>
						<CurrentPageNum>
							{" "}
							Question {activeQuestion + 1}/{questions.length}
						</CurrentPageNum>
						<IconsContainer>
							<StyledIcon
								onClick={() => setActiveQuestionIndex(Math.max(activeQuestion - 1, 0))}
								left
								disabled={activeQuestion === 0}
							>
								<ChevronLeft style={{ height: "16px", width: "16px", fill: "#264fae" }} />
							</StyledIcon>
							<StyledIcon
								onClick={() => setActiveQuestionIndex(Math.min(activeQuestion + 1, questions.length - 1))}
								right
								disabled={activeQuestion === 5}
							>
								<ChevronRight style={{ height: "16px", width: "16px", fill: "#264fae" }} />
							</StyledIcon>
						</IconsContainer>
					</Pages>
					<QuestionContainer>
						<Typography className={classes.questionText}>{getSanitizedHtmlText(text)}</Typography>
						<Box style={{ color: theme.palette.colors.basic[600] }} dangerouslySetInnerHTML={{ __html: description }} />
						{currentQuestion && getQuestionType(currentQuestion.typeId, result, currentQuestion)}
					</QuestionContainer>
					{QuestionResultInfoComponent && <QuestionResultInfoComponent result={result} attemptsCount={attemptsCount} />}
				</CaseStudyColumn>
			</CaseStudyRow>
		</>
	);
};

export default CaseStudyQuestionResult;

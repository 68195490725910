import { useMemo } from "react";

import { Messaging, deleteToken, getToken, onMessage } from "firebase/messaging";

import { FIREBASE_CONFIG } from "../../firebase/config";
import { useFirebase } from "../../firebase/useFirebase";
import { useFirebaseMessaging } from "../../firebase/useFirebaseMessaging";

const { vapidKey } = FIREBASE_CONFIG;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFirebaseNotifications = () => {
	const firebaseApp = useFirebase();
	// messagingRef will be null if messaging not supported by the browser
	const messagingRef = useFirebaseMessaging(firebaseApp);

	return useMemo(
		() =>
			messagingRef.current
				? {
						getFcmToken: async (setFcmTokenToStorage: (token: string) => void): Promise<void> => {
							await getToken(messagingRef.current as Messaging, { vapidKey })
								.then(fcmToken => {
									if (fcmToken) {
										setFcmTokenToStorage(fcmToken);
									}
								})
								.catch(error => {
									console.error("Firebase getFcmToken()", { error });
								});
						},
						onMessageListener: (onReceive: (m: { title: string; body: string }) => void): void => {
							onMessage(messagingRef.current as Messaging, payload => {
								const title = payload?.notification?.title;
								const body = payload?.notification?.body;

								if (!!title && !!body) {
									onReceive({ title, body });
								}
							});
						},
						onDeleteFcmToken: async (): Promise<void> => {
							try {
								await deleteToken(messagingRef.current as Messaging);
							} catch (error) {
								console.error("Firebase onDeleteFcmToken()", { error });
							}
						}
				  }
				: {
						getFcmToken: async () => {},
						onMessageListener: () => {},
						onDeleteFcmToken: () => {}
				  },
		[messagingRef.current]
	);
};

export default useFirebaseNotifications;

import { CreateCustomTestDto, GetQuestionCountCustomTestDto, IGetQuestionCountResult, RetakeTestDto } from "./dto";

import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto,
	FindResponse
} from "../../api/baseApiService";
import { request } from "../../api/request";

import { IDownloadTestResults, QuestionAttemptsCount, UserCustomTest, UserCustomTestHistoryResult } from "../../models";

import { URLS } from "../services.constants";

export class UserCustomTestsService extends BaseApiService<
	UserCustomTest,
	CreateCustomTestDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getQuestionCount(data: GetQuestionCountCustomTestDto): Promise<IGetQuestionCountResult> {
		return request(
			`${this.baseUrl}/question-count`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<IGetQuestionCountResult>;
	}
	retakeTest(data: RetakeTestDto): Promise<UserCustomTest> {
		return request(
			`${this.baseUrl}/retake`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserCustomTest>;
	}

	getTestDownloadURI(data: { id: number }): Promise<IDownloadTestResults> {
		return request(
			`${this.baseUrl}/create-result-pdf`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<IDownloadTestResults>;
	}

	getTestHistory({
		id,
		page,
		perPage
	}: {
		id: number;
		page: number;
		perPage: number;
	}): Promise<UserCustomTestHistoryResult> {
		return request(
			`${this.baseUrl}/history/${id}${this.serializeQuery({ page, perPage } as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<UserCustomTestHistoryResult>;
	}

	getAttemptsCount(testId: number): Promise<FindResponse<QuestionAttemptsCount>> {
		return request(`${this.baseUrl}/${testId}/question-attempts-count`, "GET") as Promise<
			FindResponse<QuestionAttemptsCount>
		>;
	}
}

export const userCustomTestsService = new UserCustomTestsService(URLS.USER_CUSTOM_TESTS);
